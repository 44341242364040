import "./App.css";

function App() {
  return (
    <div
      style={{
        position: "center",
        marginLeft: "10%",
        marginRight: "10%",
        marginTop: "10%",
      }}
    >
      <h1 style={{ color: "#4285f4" }}>StagOS Maintainer Form</h1>
    </div>
  );
}

export default App;
